<template>
  <div class="page-table scrollable only-y mainDiv mb-30">
    <div class="card-base baslikBg animated fadeInUp">
      Personel Dashboard
      <div class="tanimlamaButon">
        <el-tooltip
          :content="$t('src.views.apps.genel.refresh')"
          :open-delay="500"
          placement="top"
        >
          <el-button
            v-on:click="refreshPage()"
            style="margin-left: 15px !important"
            type="text"
            icon="el-icon-refresh"
          ></el-button>
        </el-tooltip>
      </div>
    </div>
    <div
      class="page-header card-base card-shadow--medium animated fadeInUp personPage"
      v-loading="loading || stateLoading"
      :element-loading-text="
        loading
          ? $t('src.views.apps.personel.loading')
          : $t('src.views.apps.proje.liste.stateLoading')
      "
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-row>
        <el-collapse
          class="aktifCollapse"
          style="margin-top: 10px; position: relative"
          v-model="activeCollapse"
        >
          <el-badge :value="atotal" class="item baddge"></el-badge>
          <el-collapse-item
            class="card-shadow--medium p-5"
            :title="$t('src.views.apps.personel.aktif')"
            name="1"
          >
            <el-col :lg="24">
              <el-row class="activebaslik mobilNone" style="top: 0 !important">
                <el-col :sm="6" :lg="8"> Müsteri Adı - Proje Adı</el-col>
                <el-col :sm="3" :lg="3"> Başlama Tarihi</el-col>
                <el-col
                  :sm="3"
                  style="display: flex; justify-content: end"
                  :lg="3"
                >
                  Bitiş Tarihi</el-col
                >
                <el-col :sm="4" class="text-center" :lg="3"> Toplam Gün</el-col>
                <el-col :sm="3" class="text-center" :lg="3">
                  Bitmesine Kalan Gün</el-col
                >
                <el-col :sm="4" class="text-center" :lg="4"> İşlemler</el-col>
              </el-row>
              <div
                v-if="aktifList.length == [] && isDesktop"
                style="
                  width: 100%;
                  height: 50px !important;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                {{ $t("src.views.apps.messages.noData") }}
              </div>
              <el-row
                class="mb-5 p-10 flexAlign"
                v-for="(proje, index) in aktifList"
                :key="index"
                style="
                  background-color: rgb(236 242 246 / 55%);
                  color: black;
                  border-radius: 5px;
                "
              >
                <el-col :sm="6" :md="8" :lg="8">
                  {{ proje.musteriAdi }} - {{ proje.projeBaslik }}
                  <span class="sozlesmeSpan">{{
                    proje.sozlesmeBaslik
                  }}</span></el-col
                >
                <el-col :sm="6" :md="12" :lg="6">
                  <el-row style="justify-content: space-between">
                    <el-col :sm="12" :lg="12" style="text-align: left"
                      ><span class="dekstop-none">Başlama Tarihi:</span
                      ><b
                        ><span>{{
                          new Date(proje.baslamaTarihi).toLocaleDateString()
                        }}</span></b
                      ></el-col
                    >
                    <el-col
                      :sm="12"
                      :lg="12"
                      class="mobil-respons"
                      style="text-align: right"
                      ><span class="dekstop-none">Bitiş Tarihi:</span
                      ><b
                        ><span>{{
                          new Date(proje.tamamlanmaTarihi).toLocaleDateString()
                        }}</span></b
                      >
                    </el-col>
                  </el-row>
                  <el-progress
                    :status="
                      parseFloat(proje.projeBitmeYuzdesi).toFixed(0) == '100'
                        ? 'success'
                        : parseFloat(proje.projeBitmeYuzdesi).toFixed(0) > 100
                        ? 'exception'
                        : 'exception'
                    "
                    style="padding: 0px; color: #fff; width: 100%"
                    :color="
                      parseFloat(proje.projeBitmeYuzdesi).toFixed(0) == '100'
                        ? 'rgba(33, 219, 62, 0.75)'
                        : parseFloat(proje.projeBitmeYuzdesi).toFixed(0) > 100
                        ? 'rgb(255 0 0)'
                        : 'rgba(33, 219, 62, 0.75)'
                    "
                    :percentage="
                      proje.projeBitmeYuzdesi
                        ? parseFloat(proje.projeBitmeYuzdesi).toFixed(0) / 100
                        : 0
                    "
                  />
                </el-col>
                <el-col
                  class="text-center mobil-respons"
                  :sm="3"
                  :md="7"
                  :lg="3"
                  ><span class="dekstop-none">Toplam Gün: </span>
                  {{ proje.isSuresi }}
                </el-col>
                <el-col
                  class="text-center mobil-respons"
                  :sm="3"
                  :md="7"
                  :lg="3"
                  ><span class="dekstop-none">Bitmesine Kalan Gün: </span
                  >{{ proje.bitmesineKalangun }}
                </el-col>
                <el-col class="text-center" :sm="3" :md="7" :lg="4">
                  <el-button
                    class="mt-5 kptBtn faaliyet-btn"
                    style="margin-left: 0 !important; width: 200px"
                    size="mini"
                    type="primary"
                    plain
                    icon="el-icon-document"
                    @click="faaliyetAta(proje.projeID, proje.musteriID)"
                  >
                    <label>Faaliyet Tanımla</label>
                  </el-button>
                  <el-button
                    class="mt-5 kptBtn"
                    style="margin-left: 0 !important; width: 200px"
                    size="mini"
                    type="primary"
                    plain
                    icon="el-icon-document"
                    @click="tutanakAta(proje.projeID, proje.musteriID)"
                  >
                    <label>Tutanak Tanımla</label>
                  </el-button>
                  <el-button
                    class="mt-5 kptBtn"
                    style="width: 200px"
                    v-if="!isBitirmeTarihiGecmis(proje.tamamlanmaTarihi)"
                    size="mini"
                    plain
                    type="primary"
                    icon="el-icon-check"
                    @click="
                      changeisDurum(
                        proje.gorevAtamaID,
                        '20',
                        proje.tamamlanmaTarihi
                      )
                    "
                  >
                    <label>Kapat</label>
                  </el-button>
                  <el-button
                    class="mt-5 kptBtn"
                    style="margin-left: 0 !important; width: 200px"
                    v-show="isBitirmeTarihiGecmis(proje.tamamlanmaTarihi)"
                    size="mini"
                    type="danger"
                    plain
                    icon="el-icon-warning"
                    @click="
                      changeisDurum(
                        proje.gorevAtamaID,
                        '30',
                        proje.tamamlanmaTarihi
                      )
                    "
                  >
                    <label>Sorun Bildir</label>
                  </el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-collapse-item>
        </el-collapse>
        <el-collapse
          class=""
          style="margin-top: 20px; position: relative"
          v-model="activeCollapse"
        >
          <el-badge :value="gtotal" class="item baddge"></el-badge>
          <el-collapse-item
            class="card-shadow--medium p-5"
            :title="$t('src.views.apps.personel.gelecek')"
            name="2"
          >
            <el-col :lg="24">
              <el-row class="gelecekbaslik mobilNone">
                <el-col :sm="6" :lg="8"> Müsteri Adı - Proje Adı</el-col>
                <el-col :sm="4" :lg="6"> Tahmini Başlangıç Tarihi</el-col>
                <el-col :sm="6" class="text-center" :lg="36">
                  Tahmini Başlamasına Kalan Gün</el-col
                >
              </el-row>
              <div
                v-if="baslamamisList.length == [] && isDesktop"
                style="
                  width: 100%;
                  height: 50px !important;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                {{ $t("src.views.apps.messages.noData") }}
              </div>

              <el-row
                class="mb-5 p-10 flexAlign"
                v-for="(gelecekListe, index) in baslamamisList"
                :key="index"
                style="
                  background-color: rgb(236 242 246 / 65%);
                  color: black;
                  border-radius: 5px;
                "
              >
                <el-col :sm="6" :md="8" :lg="8">
                  {{ gelecekListe.musteriAdi }} -
                  {{ gelecekListe.projeBaslik }}
                  <span class="sozlesmeSpan">{{
                    gelecekListe.sozlesmeBaslik
                  }}</span></el-col
                >
                <el-col :sm="8" :md="12" :lg="6">
                  <el-row style="justify-content: space-between">
                    <el-col :sm="12" :lg="24" style="text-align: left"
                      ><span class="dekstop-none">Tahmini Başlama Tarihi:</span
                      ><b
                        ><span>{{
                          new Date(
                            gelecekListe.baslamaTarihi
                          ).toLocaleDateString()
                        }}</span></b
                      >
                    </el-col>
                  </el-row>
                </el-col>

                <el-col
                  class="text-center mobil-respons"
                  :sm="6"
                  :md="7"
                  :lg="6"
                  ><span class="dekstop-none"
                    >Tahmini başlamasına Kalan Gün:</span
                  >
                  {{ kalanGunHesapla(gelecekListe.baslamaTarihi) }}
                </el-col>
              </el-row>
            </el-col>
          </el-collapse-item>
        </el-collapse>
        <el-collapse
          class=""
          style="margin-top: 20px; position: relative"
          v-model="activeCollapse"
        >
          <el-badge
            style="position: absolute !important"
            :value="ytotal"
            class="item baddge"
          ></el-badge>
          <el-collapse-item
            class="card-shadow--medium p-5"
            :title="'Tamamlanan İşler'"
            name="3"
          >
            <el-col :lg="24">
              <el-row class="yapilmisBaslik mobilNone">
                <el-col :sm="6" :lg="7"> Müsteri Adı - Proje Adı</el-col>
                <el-col :sm="3" :lg="2"> Başlama Tarihi</el-col>
                <el-col
                  :sm="3"
                  style="display: flex; justify-content: start"
                  :lg="2"
                >
                  Bitiş Tarihi</el-col
                >
                <el-col
                  :sm="3"
                  style="display: flex; justify-content: start"
                  :lg="3"
                >
                  Sonlandırma Tarihi</el-col
                >
                <el-col :sm="4" class="text-center" :lg="3"> Toplam Gün</el-col>
                <el-col :sm="6" class="text-center" :lg="3">
                  İş Tamamlanma Süresi</el-col
                >
              </el-row>
              <div
                v-if="tamamlanmisList.length == [] && isDesktop"
                style="
                  width: 100%;
                  height: 50px !important;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                {{ $t("src.views.apps.messages.noData") }}
              </div>
              <el-row
                class="mb-5 p-10 flexAlign"
                v-for="(bitenListe, index) in tamamlanmisList"
                :key="index"
                style="
                  background-color: rgb(236 242 246 / 65%);
                  color: black;
                  border-radius: 5px;
                "
              >
                <el-col :sm="6" :lg="7">
                  {{ bitenListe.musteriAdi }} - {{ bitenListe.projeBaslik }}
                  <span class="sozlesmeSpan">{{
                    bitenListe.sozlesmeBaslik
                  }}</span></el-col
                >
                <el-col :sm="8" :md="12" :lg="7">
                  <el-row style="justify-content: space-between">
                    <el-col :sm="12" :lg="7" style="text-align: left"
                      ><span class="dekstop-none">Başlama Tarihi:</span
                      ><b
                        ><span>
                          {{
                            new Date(
                              bitenListe.baslamaTarihi
                            ).toLocaleDateString()
                          }}</span
                        ></b
                      >
                    </el-col>
                    <el-col
                      :sm="12"
                      :lg="7"
                      class="mobil-respons"
                      style="text-align: left"
                      ><span class="dekstop-none">Bitiş Tarihi:</span
                      ><b
                        ><span>
                          {{
                            new Date(
                              bitenListe.tamamlanmaTarihi
                            ).toLocaleDateString()
                          }}</span
                        ></b
                      >
                    </el-col>
                    <el-col
                      :sm="12"
                      :lg="10"
                      class="mobil-respons"
                      style="text-align: left"
                      ><span class="dekstop-none">Sonlandırma Tarihi:</span
                      ><b
                        ><span>
                          {{
                            new Date(
                              bitenListe.isTamamlanmaTarihi
                            ).toLocaleDateString()
                          }}</span
                        ></b
                      >
                    </el-col>
                  </el-row>
                </el-col>
                <el-col
                  class="text-center mobil-respons"
                  :sm="4"
                  :md="7"
                  :lg="3"
                >
                  <span class="dekstop-none">Toplam Gün:</span>
                  {{ bitenListe.isSuresi }}
                </el-col>
                <el-col
                  class="text-center mobil-respons"
                  :sm="6"
                  :md="7"
                  :lg="3"
                >
                  <span class="dekstop-none">İş tamamlanma Süresi:</span>
                  {{
                    isBitmeSuresi(
                      bitenListe.baslamaTarihi,
                      bitenListe.isTamamlanmaTarihi
                    )
                  }}
                </el-col>
                <el-col class="text-center" :sm="24" :md="7" :lg="4">
                  <el-tooltip
                    :open-delay="300"
                    class="item"
                    effect="dark"
                    :content="
                      bitenListe.aciklama
                        ? bitenListe.aciklama
                        : 'Gösterilecek veri yok'
                    "
                    placement="left"
                  >
                    <span class="color-danger">
                      {{
                        getClosingDelay(
                          bitenListe.tamamlanmaTarihi,
                          bitenListe.isTamamlanmaTarihi
                        )
                      }}</span
                    >
                  </el-tooltip>
                </el-col>
              </el-row>
            </el-col>
          </el-collapse-item>
        </el-collapse>
      </el-row>
      <div class="mt-20">
        <FullCalendar
          id="calendar"
          ref="fullCalendar"
          :defaultView="defaultView"
          :events="eventSort"
          :locale="locale"
          :eventTimeFormat="eventTimeFormats"
          :plugins="calendarPlugins"
          :firstDay="options.firstDay"
          :customButtons="options.customButtons"
          :header="options.headerOrt"
          :height="options.height"
          :allDaySlot="options.allDaySlot"
          :slotEventOverlap="options.slotEventOverlap"
          :selectable="options.selectable"
          :navLinks="options.navLinks"
          :editable="options.editable"
          :eventLimit="options.eventLimit"
          :dayMaxEventRows="true"
          @dateClick="dayClick"
          @eventClick="eventClick"
          class="calendar-wrap card-base card-shadow--medium"
        />
      </div>
    </div>
  </div>
</template>

<script>
import personelService from "@/WSProvider/PersonelService";
import notification from "../../../notification";
import functions from "../../../functions";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import trLocale from "@fullcalendar/core/locales/tr";

var moment = require("moment");
//import timeGridPlugin from '@fullcalendar/timegrid';
//import { Calendar } from '@fullcalendar/core';

export default {
  components: {
    FullCalendar,
  },
  name: "PersonelSözleşmeListesi",
  data() {
    return {
      isDesktop: false,
      loading: false,
      stateLoading: false,
      searchLoading: false,
      editDialogLoading: false,
      editDialog: false,
      editDialogData: {},
      gorevList: [],
      aktifList: [],
      baslamamisList: [],
      tamamlanmisList: [],
      sozlesmeler: [],
      activeCollapse: ["1", "2", "3"],
      radio: "1",
      projeForm: {
        eposta: "",
        adSoyad: "",
        telefon: "",
        tc: "",
        dogumTarihi: "",
        sozlesmeID: "",
      },
      rulesProje: {},
      selectIcon: "1",
      sizes: 20,
      page: 0,
      postPage: 0,
      atotal: 4,
      gtotal: 4,
      ytotal: 4,
      firmaAdiAra: "",
      adAra: "",
      alanAra: "",
      ulkeAra: "",
      ilAra: "",
      ilceAra: "",
      baslangicTarihiAra: "",
      bitisTarihiAra: "",
      defaultView: "listMonth",
      eventSort: [],
      locale: trLocale,
      eventTimeFormats: {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      },
      calendarPlugins: [dayGridPlugin, listPlugin, interactionPlugin],
      options: {
        customButtons: {
          addEvent2: {
            text: "✚",
            click: this.cerrahiEkleme,
          },
        },
        header: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,listMonth addEvent2",
        },
        headerOrt: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,listMonth",
        },
        height: "auto",
        firstDay: 1,
        allDaySlot: true,
        slotEventOverlap: true,
        selectable: true,
        selectHelper: true,
        timeFormat: "HH:mm",
        navLinks: false, // can click day/week names to navigate views
        editable: false,
        eventLimit: true, // allow "more" link when too many events
      },
    };
  },
  created() {
    this.checkDevice();
    if (localStorage.getItem("userDataBGSurec")) {
      this.yetkiTuru = this.$store.getters.getYetkiTuru;
      this.yetkiListesi = this.$store.getters.getYetkiListesi;
      //console.log(this.yetkiListesi)
    }
  },
  mounted() {
    this.getSozlesmeListesi();
    window.addEventListener("resize", this.checkDevice);
  },
  computed: {
    filteredGorevList() {
      // Filter the elements where sDurumu is equal to 20
      return this.gorevList.filter((item) => item.isDurumu != 10);
    },
    activeGorevList() {
      // Filter the elements where sDurumu is equal to 10 and baslamaTarihi has already started
      const currentDate = new Date().toISOString().split("T")[0]; // Get the current date in "yyyy-MM-dd" format
      return this.gorevList.filter((item) => item.baslamaTarihi <= currentDate);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },
  methods: {
    faaliyetAta(projeID, musteriID) {
      this.$store.commit("setFaaliyetData", { projeID, musteriID });
      functions.routeSayfa("Faaliyet Tanımla", this);
    },
    tutanakAta(projeID, musteriID) {
      this.$store.commit("setTutanakData", { projeID, musteriID });
      functions.routeSayfa("Tutanak Tanımla", this);
    },
    getClosingDelay(completionDate, closingDate) {
      if (!completionDate || !closingDate) return ""; // Handle if the dates are missing

      const completionTime = new Date(completionDate).getTime();
      const closingTime = new Date(closingDate).getTime();

      const timeDifference = closingTime - completionTime;
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      if (daysDifference > 0) {
        return `${daysDifference} gün geç kapandı`;
      }
    },
    isBitmeSuresi(baslamaTarihi, tamamlanmaTarihi) {
      const baslamaTarihiObj = new Date(baslamaTarihi);
      const tamamlanmaTarihiObj = new Date(tamamlanmaTarihi);
      const fark = tamamlanmaTarihiObj.getTime() - baslamaTarihiObj.getTime();
      const isBitmeSuresi = Math.ceil(fark / (1000 * 60 * 60 * 24));
      return isBitmeSuresi > 0 ? isBitmeSuresi : 0; // Eğer iş bitme süresi negatifse veya sıfırsa 0 döndürür.
    },
    kalanGunHesapla(baslamaTarihi) {
      const baslamaTarihiObj = new Date(baslamaTarihi);
      const bugununTarihiObj = new Date();
      const fark = baslamaTarihiObj.getTime() - bugununTarihiObj.getTime();
      const kalanGun = Math.ceil(fark / (1000 * 60 * 60 * 24));
      return kalanGun > 0 ? kalanGun : 0; // Eğer başlama tarihi bugünden önceyse 0 döndürür.
    },
    calculateProgressPercentages() {
      this.aktifList.forEach((proje) => {
        const baslamaTarihi = new Date(proje.baslamaTarihi);
        const tamamlanmaTarihi = new Date(proje.tamamlanmaTarihi);
        // ! ETrak 1 de tamamlanmaTarih yanlis geliyor
        const simdikiTarih = new Date();
        const toplamGunSayisi =
          (tamamlanmaTarihi - baslamaTarihi) / (1000 * 60 * 60 * 24);
        const gecenGunSayisi =
          (simdikiTarih - baslamaTarihi) / (1000 * 60 * 60 * 24);
        proje.projeBitmeYuzdesi = (gecenGunSayisi / toplamGunSayisi) * 100;
      });
    },
    calculateProgressPercentage() {
      const baslamaTarihi = new Date(this.aktifList.baslamaTarihi);
      const tamamlanmaTarihi = new Date(this.aktifList.tamamlanmaTarihi);
      const simdikiTarih = new Date();

      const toplamGunSayisi =
        (tamamlanmaTarihi - baslamaTarihi) / (1000 * 60 * 60 * 24);
      const gecenGunSayisi =
        (simdikiTarih - baslamaTarihi) / (1000 * 60 * 60 * 24);

      return (gecenGunSayisi / toplamGunSayisi) * 100;
    },
    checkDevice() {
      this.isDesktop = window.innerWidth >= 768;
    },
    isBitirmeTarihiGecmis(tarih) {
      const bugun = new Date();
      const tarihObjesi = new Date(tarih);
      return tarihObjesi < bugun;
    },
    open() {
      setTimeout(() => {
        this.$alert("Gösterilecek veri bulunmamaktadır", "Uyarı!", {
          confirmButtonText: "OK",
        });
      }, 0);
    },
    refreshPage() {
      this.page = 1;
      this.postPage = 0;

      // this.getProjeSayisi();
      this.getSozlesmeListesi();
    },
    changeisDurum(selection, durum, tamamlanmaTarihi) {
      const tarih = new Date().toISOString().slice(0, 19).replace("T", " ");

      const completionTime = new Date(tamamlanmaTarihi).getTime();
      const closingTime = new Date(tarih).getTime();
      const timeDifference = closingTime - completionTime;
      const gecikenTarih = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      const promptOptions = {
        confirmButtonText: this.$t("src.views.apps.genel.yes"),
        cancelButtonText: this.$t("src.views.apps.genel.no"),
      };

      if (durum == 30) {
        this.$prompt(
          "İş Gecikmeye Uğradı!!!",
          this.$t("src.views.apps.genel.uyari"),
          {
            ...promptOptions,
            confirmButtonText: "Sorun bildir ve işi sonlandır",
            showCancelButton: false,
            inputType: "textarea",
            inputPattern: /^.{1,300}$/,
            inputErrorMessage:
              "Lütfen 1 ila 300 karakter arasında bir mesaj girin.",
            inputPlaceholder: "Sebep",
          }
        )
          .then(({ value }) =>
            this.handleConfirmation(
              selection,
              durum,
              value,
              tarih,
              gecikenTarih
            )
          )
          .catch(() => {});
      } else {
        this.$confirm(
          "İşi sonlandırmak istediğinizden emin misiniz?",
          this.$t("src.views.apps.genel.uyari"),
          {
            ...promptOptions,
            type: "warning",
          }
        )
          .then(() => this.handleConfirmation(selection, durum, "", tarih, 0))
          .catch(() => {});
      }
    },
    handleConfirmation(selection, durum, mesaj, tarih, gecikenTarih) {
      try {
        this.stateLoading = true;
        var item = durum;
        personelService
          .gorevGuncelle(selection, item, mesaj, tarih, gecikenTarih)
          .then((response) => {
            if (response.status === 200) {
              this.getSozlesmeListesi();
            }
            this.stateLoading = false;
            notification.Status("success", this, response.msg);
            localStorage.setItem("userDataBGSurec", response.token);
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status === 401) {
                notification.Status(150, this);
              } else {
                notification.Status("errorMsg", this, error.errMsg);
              }
            } else {
            }
            this.stateLoading = false;
          });
      } catch (e) {
        this.stateLoading = false;
      }
    },
    getSozlesmeListesi() {
      try {
        this.loading = true;
        personelService
          .personelSozlesmeListesi(this.selectIcon, this.postPage, this.sizes)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.aktifList = response.data.aktif;
              this.baslamamisList = response.data.baslamamis;
              this.tamamlanmisList = response.data.biten;
              // this.atotal = parseInt(response.count.countAktif);
              // this.gtotal = parseInt(response.count.countBaslamamis);
              // this.ytotal = parseInt(response.count.countBiten);
              let dizi = [
                "#746363",
                "#06873c",
                "#120ff1",
                "#feb303",
                "#8e44ad",
                "#cad515",
                "#1abc9c",
                "#3498db",
                "#95a5a6",
                "#634d12",
              ];
              var renk = document.getElementById("renk");
              let sira = 0;

              function siradakiRenk() {
                const secilenRenk = dizi[sira];
                sira = (sira + 1) % dizi.length;
                return secilenRenk;
              }

              this.aktifList.forEach((item) => {
                const secilenRenk = siradakiRenk();
                this.eventSort.push({
                  title: item.projeBaslik,
                  start: item.baslamaTarihi,
                  end: item.tamamlanmaTarihi,
                  color: secilenRenk,
                });
              });
              this.calculateProgressPercentages();
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.gorevList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },
    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.stateLoading = false;
    },
    startUpdate() {
      this.projeForm.adSoyad = this.editDialogData.adSoyad;
      this.projeForm.eposta = this.editDialogData.mail;
      this.projeForm.telefon = this.editDialogData.telefon;
      this.projeForm.tc = this.editDialogData.tc;
      // this.projeForm.dogumTarihi = moment(this.editDialogData.dogumTarihi).format("DD-MM-YYYY");
    },
    updatePage(data) {
      this.$store.commit("changeProjeUpdateData", data.projeID);
      functions.routeSayfa("Proje Güncelle", this);
    },
    routeProje(name, scope) {
      if (scope) {
        this.$store.commit("changeProjeUpdateData", scope);
      }
      functions.routeSayfa(name, this);
    },
    indexMethod(index) {
      return (this.page - 1) * 10 + index + 1;
    },
    handleSizeChange(val) {
      this.sizes = val;
      this.getSozlesmeListesi();
    },
    handleChange(event) {
      this.selectIcon = event;
      this.page = 1;
      this.postPage = 0;
      this.getSozlesmeListesi();
      this.firmaAdiAra = "";
      this.adAra = "";
      this.alanAra = "";
      this.ulkeAra = "";
      this.ilAra = "";
      this.ilceAra = "";
      this.baslangicTarihiAra = "";
      this.bitisTarihiAra = "";
      document
        .querySelector(".scrollable")
        .scrollTo({ top: 0, behavior: "smooth" });
    },
    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      this.getSozlesmeListesi();
    },
    dayClick(arg) {},
    eventClick(arg) {},
  },
};
</script>

<style lang="scss">
.el-table .info-row {
  background: rgba(125, 199, 225, 0.45);
}

.el-table .error-row {
  background: rgba(249, 0, 26, 0.08);
}

.el-table .success-row {
  background: rgba(3, 249, 0, 0.08);
}

.el-table .warning-row {
  background: rgba(245, 249, 0, 0.08);
}

.personPage .el-collapse:first-child .el-collapse-item__header {
  color: white;
  text-align: center;
  font-size: 20px !important;
  padding: 3px 10px;
  font-weight: 500;
  border-radius: 5px 5px 0 0;
  background-color: #5e9ea0;
}

.personPage .el-collapse:nth-child(2) .el-collapse-item__header {
  color: white;
  text-align: center;
  font-size: 20px !important;
  padding: 3px 10px;
  font-weight: 500;
  border-radius: 5px 5px 0 0;
  background-color: #ea967a;
}

.personPage .el-collapse:nth-child(3) .el-collapse-item__header {
  color: white;
  text-align: center;
  font-size: 20px !important;
  padding: 3px 10px;
  font-weight: 500;
  border-radius: 5px 5px 0 0;
  background-color: #2f4f4e;
}
</style>
<style lang="scss" scoped>
.faaliyet-btn {
  background-color: #bde8ff;
}

.el-collapse-item .el-row {
  position: static !important;
}

.refresh {
  color: #fff;
  float: right;
  padding: 0 !important;
  transition: all 0.5s;
}

.activeS {
  background-color: green;
  color: white;
}

.dangerS {
  background-color: red;
  color: white;
}

.refresh:hover {
  transform: rotate(360deg);
}

.el-pagination {
  margin-top: 20px;
  float: right;
}

.el-radio-button--mini .el-radio-button__inner label {
  position: relative;
  bottom: 5px;
}

/*
    .el-table .el-table__cell.is-right{
        position:fixed
    }*/

.kaldirilmis {
  background: #ec205f;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
}
</style>
<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

@media (min-width: 768px) {
  .el-radio-button--mini .el-radio-button__inner {
    padding: 0 10px !important;
    font-size: 11px;
    border-radius: 0;
  }
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.lineH {
  line-height: 40px;
  font-size: 16px !important;
}

.activeList {
  color: white;
  text-align: center;
  font-size: 20px !important;
  padding: 10px;
  font-weight: 500;
  border-radius: 5px 5px 0 0;
  background-color: #5e9ea0;
}

.gelecekList {
  color: white;
  text-align: center;
  font-size: 20px !important;
  padding: 10px;
  font-weight: 500;
  border-radius: 5px 5px 0 0;
  background-color: #ea967a;
}

.tamamlananList {
  color: white;
  text-align: center;
  font-size: 20px !important;
  padding: 10px;
  font-weight: 500;
  border-radius: 5px 5px 0 0;
  background-color: #2f4f4e;
}

.cizgi {
  width: 2px;
  display: inline-flex;
  height: 30px;
  background-color: #006cae29;
  justify-content: center;
  margin: 0 15px;
}

.activebaslik {
  background-color: #5e9ea070;
  padding: 10px;
  color: rgb(39, 38, 38);
}

.gelecekbaslik {
  background-color: #ea967a78;
  padding: 10px;
  color: rgb(39, 38, 38);
}

.yapilmisBaslik {
  background-color: #2f4f4e78;
  padding: 10px;
  color: rgb(39, 38, 38);
}

.dekstop-none {
  display: none;
}

button .el-icon-check,
button .el-icon-warning {
  float: left !important;
}

.color-danger {
  color: red;
}

.baddge {
  position: absolute !important;
  left: -10px;
  top: -8px;
}

.baddge .el-badge__content {
  background-color: red !important;
  color: white;
  width: 25px;
  height: 25px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.sozlesmeSpan {
  display: block;
  line-height: 11px;
  color: #af9a9a;
}

@media screen and (min-width: 820px) {
  .flexAlign {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 820px) {
  .kptBtn {
    width: 150px !important;
  }
}

@media screen and (max-width: 480px) {
  .mobilNone {
    display: none !important;
  }

  .dekstop-none {
    display: initial !important;
  }

  .mobil-respons {
    text-align: left !important;
  }
}
</style>
